import React, { useEffect } from "react";
import { ApiClient } from "../../utils/ApiClient";
import { useState } from "react";
import { Table, Button } from "antd";
import dayjs from "dayjs";
import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import { getUser } from "../../store/slices/userSlice";
import toast from "react-hot-toast";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import { Bill } from "../../utils/Bill";

import generatePDF from "react-to-pdf";
import ReactToPrint from "react-to-print";
import Filter from "./Filter";
import { render } from "@testing-library/react";
import StatusTag from "./StatusTag";
import moment from "moment";
import { TfiReload } from "react-icons/tfi";
import { Row, Col, Form, Input, Select, DatePicker } from "antd";
import { PRICES } from "../../utils/Prices";

export const CricketBooking = () => {
  const targetRef = React.useRef(null);
  const [barcode, setBarcode] = useState("");
  const [cricketEvents, setCricketEvents] = useState([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [discountValue, setDiscountValue] = React.useState(0);
  const [componentSize, setComponentSize] = useState("large");
  const [noOfWeeks, setNoOfeWeeks] = useState(null);
  const [optionsUser, setOptionsUser] = useState([]);
  const [selecetedUser, setSelectedUser] = useState();
  const [userData, setUserData] = useState([]);
  const [openRecursive, setOpenRecursive] = useState(false);
  const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] =
    useState();
  const [rate, setRate] = useState(750);
  const user = useSelector(getUser);
  const [open, setOpen] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [dateNew, setDateNew] = useState(null);
  const [phone, setPhone] = useState();
  const [withMachine, setWithMachine] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [optionsIn, setOptionsIn] = useState([]);
  const [optionsOut, setOptionsOut] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [customerName, setCustomerName] = useState();
  const [selectedUsageStatus, setSelectedUsageStatus] = useState([
    "Pending",
    "Check-In",
    "Check-Out",
    "Cancelled",
    "Closed",
  ]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([
    "Pending",
    "Paid",
  ]);
  const [selectedDateForFilter, setSelectedDateForFilter] = useState(dayjs());
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchBookings();
  }, []);

  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handleChangeUser = async (value) => {
    // console.log(value);
    setSelectedUser(value);
  };

  useEffect(() => {
    setOptionsUser(
      userData.map((item) => ({
        value: item._id,
        label: item.email,
      }))
    );
  }, [userData]);

  const handleSubmitRecursive = async () => {
    const body = {
      createdBy: selecetedUser,
      startTime: checkInTime,
      endTime: checkOutTime,
      phone: phone,
      noOfWeeks: noOfWeeks,
    };
    setOpenRecursive(false);
    try {
      const response = await ApiClient.post(`/cricket-event/recursive`, body);
      //console.log(response);
      setCheckInTime(null);
      setCheckOutTime(null);
      setDateNew(null);
      setCheckInTime(null);
      setCheckOutTime(null);

      // fetchData();
      fetchBookings();
      setSelectedUser(null);
      setNoOfeWeeks(null);
      setPhone(null);
      setSelectedDate(null);
      toast.success("Reservation made successfully");
    } catch (error) {
      // console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await ApiClient.get(`/user/getUsers`);
      setUserData(response.data);
    } catch (error) {
      //console.error(error);
      toast.error("Failed to fetch user details");
    }
  };

  useEffect(() => {
    if (openRecursive === true) {
      fetchUserDetails();
    }
  }, [openRecursive]);

  const deleteCricketEvent = async (id) => {
    try {
      await ApiClient.delete(`/cricket-event/delete/${id}`);
      fetchBookings();
      toast.success("Booking Deleted Successfully");
    } catch (error) {
      //console.log(error);
      toast.error("Failed to delete booking");
    }
  };

  const [valueIn, setValueIn] = useState();
  const [valueOut, setValueOut] = useState();

  const handlePaymentSubmit = async () => {
    handlePrint();
    try {
      //console.log(selectedSubscriptionDetails);
      const response = await ApiClient.put(
        `/cricket-event/payment/${selectedSubscription}`,
        {
          cashierId: user._id,
          userId: selectedSubscriptionDetails.userId,
          amount:
            (selectedSubscriptionDetails?.withMachine === true
              ? PRICES.CRICKET_COURT_WITH_BALLING_MACHINE
              : PRICES.CRICKET_COURT_WITHOUT_BALLING_MACHINE) * qty,
          paymentCategory: "CricketEvent",
          barcode: barcode,
          discount: discountValue,
          userName:customerName!==null?customerName:null,
          fine: selectedSubscriptionDetails?.fine,
        }
      );
      //console.log(response.data);
      fetchBookings();
      toast.success("Payment Successful");
    } catch (error) {
      // console.error(error);
      toast.error("Payment Failed");
    }
    // await paymentSubscription();
    setOpenPaymentModal(false);
  };

  const handlePaymentClose = () => {
    // setSelectedSubscription(null);
    setSelectedSubscription(null);
    setOpenPaymentModal(false);
  };
  const [qty, setQty] = useState(1);

  const handlePaymentOpen = async (data) => {
    //console.log(data);
    setQty((dayjs(data.end) - dayjs(data.start)) / 3600000);
    const difference = dayjs(data.checkOutTime).diff(dayjs(data.end), "minute") + 1;
    const noOfQuarters = Math.floor(difference / 15);
    data.fine = noOfQuarters > 0 ?  noOfQuarters * PRICES.FINE : 0;
    setOpenPaymentModal(true);
    setSelectedSubscriptionDetails(data);
    setSelectedSubscription(data.id);
  };

  const handleCheckIn = async (record) => {
    try {
      // console.log(record);
      // console.log("Check-In");
      await ApiClient.put(`/cricket-event/checkin/${record.id}`)
        .then((res) => {
          fetchBookings();
          toast.success("Checked In Successfully");
        })
        .catch((err) => {
          toast.error("Failed to check in");
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleCheckOut = async (record) => {
    try {
      // console.log(record);
      // console.log("Check-Out");
      await ApiClient.put(`/cricket-event/checkout/${record.id}`)
        .then((res) => {
          fetchBookings();
          toast.success("Checked Out Successfully");
        })
        .catch((err) => {
          toast.error("Failed to check out");
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleChangeI = async (value) => {
    setValueIn(value);
    setStartHour(value + 1);
    const newDate = new Date(dateNew);
    const newDate1 = new Date(newDate.setHours(value));
    const newDate2 = new Date(newDate1.setMinutes(0));
    setCheckInTime(newDate2);
  };

  const handleChangeO = (value) => {
    setValueOut(value);
    const newDate = new Date(dateNew);
    const newDate1 = new Date(newDate.setHours(value));
    const newDate2 = new Date(newDate1.setMinutes(0));
    setCheckOutTime(newDate2);
  };

  const cricketEventsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Check-In",
      dataIndex: "checkIn",
      key: "checkIn",
    },
    {
      title: "Check-Out",
      dataIndex: "checkOut",
      key: "checkOut",
    },
    {
      title: "Checked-Out Time",
      dataIndex: "checkOutTime",
      key: "checkOutTime",
      render: (text, record) => (
        <span>
          {record.checkOutTime
            ? dayjs(record.checkOutTime).format("HH:mm")
            : "Not Checked-Out"}
        </span>
      ),
    },
    {
      title: "With Machine",
      dataIndex: "withMachine",
      key: "withMachine",
      render: (text) => <span>{text === true ? "Yes" : "No"}</span>,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>{`Rs.${calculateFee(record)}/=`}</span>,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Usage Status",
      dataIndex: "usageStatus",
      key: "usageStatus",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="d-flex gap-2">
          {record.paymentStatus == "Pending" &&
          record.usageStatus == "Pending" ? (
            <>
              {dayjs(record.start) < dayjs().add(5, "minute") && (
                <Button
                  onClick={() => {
                    handleCheckIn(record);
                  }}
                >
                  Check-In
                </Button>
              )}
              <Button
                onClick={() => deleteCricketEvent(record.id)}
                className="d-flex justify-content-center align-items-center"
              >
                <DeleteOutlined />
              </Button>
            </>
          ) : record.paymentStatus == "Pending" &&
            record.usageStatus == "Check-In" ? (
            <>
              <Button
                onClick={() => {
                  handleCheckOut(record);
                }}
              >
                Check-Out
              </Button>
            </>
          ) : record.paymentStatus == "Pending" &&
            record.usageStatus == "Check-Out" ? (
            <>
              <Button
                onClick={() => {
                  handlePaymentOpen(record);
                  setBarcode(BarcodeIdGenerate());
                }}
              >
                Payment
              </Button>
            </>
          ) : null}
        </div>
      ),
    },
  ];

  const prepareEventTableData = (data) => {
    return data?.map((item) => ({
      ...item,
      date: formattedDate(item.start),
      checkIn: formattedTime(item.start),
      checkOut: formattedTime(item.end),
    }));
  };

  const formattedTime = (time) => {
    return dayjs(time).format("HH:mm");
  };

  const formattedDate = (date) => {
    return dayjs(date).format(" YYYY MMM DD");
  };

  const calculateFee = (record) => {
    return (
      ((dayjs(record.end).valueOf() - dayjs(record.start).valueOf()) /
        3600000) *
      (record.withMachine ? 1300 : 1000)
    );
  };

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get("/cricket-event/");
      setCricketEvents(prepareEventTableData(response.data));
      //console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      //console.log(error);
      setIsLoading(false);
      toast.error("Failed to fetch bookings");
    }
  };

  const generateOptionsIn = () => {
    const options = [];
    for (let hour = 5; hour <= 22; hour++) {
      const label = `${hour.toString().padStart(2, "0")}:00:00`;
      options.push({ value: hour, label });
    }
    return options;
  };
  const [startHour, setStartHour] = useState(5);
  useEffect(() => {
    setFilteredData(
      cricketEvents.filter((item) => {
        return (
          (selectedUsageStatus.includes(item.usageStatus) ||
            selectedPaymentStatus.includes(item.paymentStatus)) &&
          dayjs(item.date).format("YYYY-MM-DD") ===
            selectedDateForFilter.format("YYYY-MM-DD")
        );
        // return()
      })
    );
  }, [
    cricketEvents,
    selectedDateForFilter,
    selectedPaymentStatus,
    selectedUsageStatus,
  ]);

  const generateOptionsOut = () => {
    const options = [];
    for (let hour = startHour; hour <= 23; hour++) {
      const label = `${hour.toString().padStart(2, "0")}:00:00`;
      options.push({ value: hour, label });
    }
    return options;
  };

  const usageStatuses = [
    {
      key: "Pending",
      value: "Pending",
      label: "Pending",
    },
    {
      key: "Check-In",
      value: "Check-In",
      label: "Check-In",
    },
    {
      key: "Check-Out",
      value: "Check-Out",
      label: "Check-Out",
    },
    {
      key: "Cancelled",
      value: "Cancelled",
      label: "Cancelled",
    },
    {
      key: "Closed",
      value: "Closed",
      label: "Closed",
    },
  ];

  const paymentStatuses = [
    {
      key: "Pending",
      value: "Pending",
      label: "Pending",
    },
    {
      key: "Paid",
      value: "Paid",
      label: "Paid",
    },
  ];

  const onChange = (date, dateString) => {
    //console.log(dateString);
    setSelectedDate(date);
    setDateNew(dateString);
  };

  useEffect(() => {
    setOptionsOut(generateOptionsOut());
  }, [startHour]);

  useEffect(() => {
    setOptionsIn(generateOptionsIn());
  }, []);

  const customer = "66443113b7997dd04993e509";

  const resetFields = () => {
    setCheckInTime(null);
    setCheckOutTime(null);
    setDateNew(null);
    setCheckInTime(null);
    setCheckOutTime(null);
    setPhone(null);
    setSelectedDate(null);
    setWithMachine(null);
    setValueIn(null);
    setValueOut(null);
  };

  const handleSubmit = async () => {
    const body = {
      createByName: customerName,
      startTime: checkInTime,
      endTime: checkOutTime,
      phone: phone,
      withMachine: withMachine,
    };
    //console.log(body);
    setOpen(false);
    const response = await ApiClient.post(`/cricket-event`, body)
      .then(async () => {
        await fetchBookings();
        toast.success("Reservation made successfully");
        resetFields();
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(
          `Failed to create new reservation : ${error.response.data.message}`
        );
        resetFields();
      });
  };

  useEffect(() => {
    if (valueIn !== null) {
      handleChangeI(valueIn);
    }
    if (valueOut !== null) {
      handleChangeO(valueOut);
    }
  }, [dateNew]);

  useEffect(() => {
    setOptionsOut(generateOptionsOut());
  }, [startHour]);

  useState(() => {
    setOptionsIn(generateOptionsIn());
  }, []);

  useEffect(() => {
    fetchBookings();
  }, []);
  return (
    <div>
      <div className="row d-flex justify-content-center my-2 gap-2">
        <div className="col-md-3">
          <Button
            type="primary"
            className=""
            onClick={() => {
              setOpen(true);
              setCheckInTime(null);
              setCheckOutTime(null);
              setCustomerName(null);
              setPhone(null);
              setWithMachine(null);

            }}
          >
            + New Reservation
          </Button>
        </div>
        <div className="col-md-3">
          <Button type="primary" onClick={() => fetchBookings()}>
            <TfiReload />
          </Button>
        </div>
        <div className="col-md-3">
          <Button
            type="primary"
            className=""
            onClick={() => {
              setOpenRecursive(true);
              setCheckInTime(null);
              setCheckOutTime(null);
            }}
          >
            + Recursive Reservation
          </Button>
        </div>
      </div>
      <Filter
        usageStatuses={usageStatuses}
        paymentStatuses={paymentStatuses}
        setSelectedUsageStatus={setSelectedUsageStatus}
        setSelectedPaymentStatus={setSelectedPaymentStatus}
        setSelectedDate={setSelectedDateForFilter}
        selectedDate={selectedDate}
      />
      <Table
        dataSource={filteredData}
        columns={cricketEventsColumns}
        loading={isloading}
        scroll={{ x: 1300 }}
      />
      <Modal
        title={`Make a Reservation for Cricket Court`}
        centered
        open={openRecursive}
        onOk={() => handleSubmitRecursive()}
        okButtonProps={{
          disabled:
            !checkInTime ||
            !checkOutTime ||
            !dateNew ||
            !selecetedUser ||
            noOfWeeks > 15 ||
            noOfWeeks < 2,
        }}
        onCancel={() => {
          setOpenRecursive(false);
          setCheckInTime(null);
          setCheckOutTime(null);
          setDateNew(null);
          setPhone(null);
          setSelectedDate(null);
          setValueIn(null);
          setValueOut(null);
          setNoOfeWeeks(null);
          setSelectedUser(null);
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="Select User">
                  <Select
                    placeholder="Select One"
                    style={{ width: "100%" }}
                    onChange={handleChangeUser}
                    value={selecetedUser}
                    options={optionsUser}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                  />
                </Form.Item>

                <Form.Item label="Phone Number">
                  <Input
                    type="number"
                    style={{ width: "100%" }}
                    value={phone}
                    placeholder="07XXXXXXXX"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Item>

                <Form.Item label="Start Date">
                  <DatePicker
                    value={selectedDate}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="No of Weeks"
                  rules={[
                    {
                      required: true,
                      message: "Please input the number of weeks",
                    },
                    {
                      type: "number",
                      min: 2,
                      max: 15,
                      message: "Number of weeks must be between 1 and 15",
                      transform: (value) => Number(value),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    style={{ width: "100%" }}
                    value={noOfWeeks}
                    placeholder="Maximum 15 Weeks"
                    onChange={(e) => setNoOfeWeeks(e.target.value)}
                    max={15}
                    min={2}
                  />
                </Form.Item>

                <Form.Item label="Check-In">
                  <Select
                    placeholder="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeI}
                    value={checkInTime && checkInTime.getHours()}
                    options={optionsIn}
                  />
                </Form.Item>
                <Form.Item label="Check-Out">
                  <Select
                    placeholder="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeO}
                    value={checkOutTime && checkOutTime.getHours()}
                    options={optionsOut}
                    disabled={!checkInTime}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Payment Subscription"
        open={openPaymentModal}
        onCancel={handlePaymentClose}
        onOk={handlePaymentSubmit}
        footer={[
          <Button key="back" onClick={handlePaymentClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <Bill
          targetRef={targetRef}
          tempItem={[
            {
              name: `Cricket Booking ${
                selectedSubscriptionDetails?.withMachine === true
                  ? "With"
                  : "Without"
              } Machine`,
              count: qty,
              price:
                selectedSubscriptionDetails?.withMachine === true ? 1300 : 1000,
            },
          ]}
          total={
            (selectedSubscriptionDetails?.withMachine === true ? 1300 : 1000) *
            qty
          }
          subTotal={
            (selectedSubscriptionDetails?.withMachine === true ? 1300 : 1000) *
            qty
          }
          balance={
            (selectedSubscriptionDetails?.withMachine === true ? 1300 : 1000) *
            qty
          }
          fine={selectedSubscriptionDetails?.fine}
          barcode={barcode}
          customer={selectedSubscriptionDetails?.name}
          discount={0}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
        />
      </Modal>
      <Modal
        title={`Make a Reservation for Cricket Court`}
        centered
        open={open}
        onOk={() => handleSubmit()}
        okButtonProps={{
          disabled:
            !checkInTime ||
            !checkOutTime ||
            !dateNew ||
            withMachine === null,
        }}
        onCancel={() => {
          setOpen(false);
          setCheckInTime(null);
          setCheckOutTime(null);
          setDateNew(null);
          setPhone(null);
          setSelectedDate(null);
          setValueIn(null);
          setValueOut(null);
          setWithMachine(null);
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="User Name">
                  <Input
                    style={{ width: "100%" }}
                    value={customerName}  
                    onChange={(e) => setCustomerName(e.target.value)}

                  />
                </Form.Item>

                <Form.Item label="Phone Number">
                  <Input
                    type="number"
                    style={{ width: "100%" }}
                    value={phone}
                    placeholder="07XXXXXXXX"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Item>

                <Form.Item label="Date">
                  <DatePicker
                    value={selectedDate}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                  />
                </Form.Item>

                <Form.Item label="Check-In">
                  <Select
                    placeholder="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeI}
                    value={checkInTime && checkInTime.getHours()}
                    options={optionsIn}
                  />
                </Form.Item>
                <Form.Item label="Check-Out">
                  <Select
                    placeholder="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeO}
                    value={checkOutTime && checkOutTime.getHours()}
                    options={optionsOut}
                    disabled={!checkInTime}
                  />
                </Form.Item>
                <div>
                  {" "}
                  <Form.Item label="Options">
                    <Select
                      placeholder="Select Time"
                      style={{ width: "100%" }}
                      options={[
                        { value: null, label: "Select from Here" },
                        { value: true, label: "With Balling Machine" },
                        { value: false, label: "Without Balling Machine" },
                      ]}
                      onChange={(e) => setWithMachine(e)}
                      value={withMachine}
                    />
                  </Form.Item>
                  <Col span={24} offset={0}>
                    <div className="d-flex align-items-end flex-column">
                      <h4>
                        Total: $
                        {(withMachine ? 1300 : 1000) * (valueOut - valueIn)}
                      </h4>
                    </div>
                  </Col>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
