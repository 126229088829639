import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Input, Form, InputNumber, Row, Col, Select, DatePicker, Space, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons"; // Import the Delete icon
import toast from "react-hot-toast";
import ModalEdit from "./ModalEdit";
import { ApiClient } from "../../utils/ApiClient";

const GymPackage = () => {
  const [formBuy, setFormBuy] = useState([]);
  const [packageData, setPackageData] = useState();
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [packageOption, setPackageOption] = useState([]);
  const [tempKey, setTempKey] = useState();
  const [emails, setEmails] = useState([]);
  const [datas, setDatas] = useState([]);
  const [attitude, setAttitude] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [componentSize, setComponentSize] = useState("large");
  const [form, setForm] = useState([]);
  const [newValue, setNewValue] = useState();

  const handleSubmitBuy = async () => {
    setOpenBuyModal(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    await ApiClient.get("/gym-packages/")
      .then((response) => {
        setIsLoading(false);
        setPackageData(response.data);
        setPackageOption(
          response.data.map((item) => ({ value: item._id, label: item.name }))
        );
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Failed to fetch data");
      });
  };

  const fetchUsers = async () => {
    await ApiClient.get("/user/email")
      .then((response) => {
        setEmails(
          response.data.map((item) => ({
            value: item.email,
            label: item.email,
          }))
        );
      })
      .catch((error) => {
        toast.error("Failed to fetch data");
      });
  };

  const handleDelete = async (id) => {
    await ApiClient.delete(`/gym-packages/${id}`)
      .then((response) => {
        fetchData();
        toast.success("Package Deleted Successfully");
      })
      .catch((error) => {
        toast.error("Failed to delete package");
      });
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await ApiClient.put(`/gym-packages/${datas._id}`, {
      value: newValue,
      attitude: attitude,
    })
      .then((response) => {
        fetchData();
        toast.success("Package Updated Successfully");
      })
      .catch((error) => {
        toast.error("Failed to update package");
      });
  };

  const handleSubmitAdd = async () => {
    setOpenAddModal(false);
    await ApiClient.post(`/gym-packages/`, {
      name: form.name,
      rate: form.rate,
    })
      .then((response) => {
        fetchData();
        toast.success("Package Added Successfully");
      })
      .catch((error) => {
        toast.error("Failed to add package");
      });
  };

  const columns = [
    {
      title: "Package Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, key) => (
        <a id="displayName" onClick={(e) => handleClick(key, e.target.id)}>
          {text}
        </a>
      ),
    },
    {
      title: "Price",
      dataIndex: "rate",
      key: "Price",
      render: (text, key) => (
        <a id="rate" onClick={(e) => handleClick(key, e.target.id)}>
          Rs.{text}/=
        </a>
      ),
    },
    {
      title: "No of Pax",
      dataIndex: "pax",
      key: "pax",
      render: (text, key) => (
        <span>{`${key.pax > 1 ? key.pax + " Persons" : key.pax + " Person"}`}</span>
      ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (text, key) => (
        <span>{`${key.duration} Months`}</span>
      ),
    },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   render: (text, key) => (
    //     <Popconfirm
    //       title="Are you sure to delete this package?"
    //       onConfirm={() => handleDelete(key._id)}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Button
    //         icon={<DeleteOutlined />} // Use the Delete icon
    //         type="danger"
    //       />
    //     </Popconfirm>
    //   ),
    // },
  ];

  const onChange = (value) => {
    setFormBuy({ ...formBuy, email: value });
  };
  const onSearch = (value) => {
    // console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const changeDate = (date, dateString) => {
    setFormBuy({ ...formBuy, start: dateString });
  };

  const checkQuantity = (rule, value, callback) => {
    if (value <= 0) {
      callback("Quantity must be greater than 0");
    } else {
      callback();
    }
  };

  const handleClick = (key, data) => {
    setDatas(key);
    setAttitude(data);
    setIsModalOpen(true);
    setNewValue(key[data]);
  };
  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handleAdd = async () => {
    setOpenAddModal(true);
  };

  useEffect(() => {
    fetchUsers();
  }, [tempKey]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <ModalEdit
        datas={datas}
        attitude={attitude}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        newValue={newValue}
        setNewValue={setNewValue}
      />
      <Table columns={columns} dataSource={packageData} loading={isloading} scroll={{ x: 1300 }} />
      <Modal
        title="Modal 1000px width"
        centered
        open={openAddModal}
        onOk={() => handleSubmitAdd()}
        onCancel={() => {
          setOpenAddModal(false);
          setTempKey("");
        }}
        width={500}
      >
        {/* Rest of the modal content */}
      </Modal>
      <Modal
        title="Modal 1000px width"
        centered
        open={openBuyModal}
        onOk={() => handleSubmitBuy()}
        onCancel={() => setOpenBuyModal(false)}
        width={500}
      >
        {/* Rest of the modal content */}
      </Modal>
    </div>
  );
};

export default GymPackage;
