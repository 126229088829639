import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { MdPersonAdd } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { ApiClient } from '../../utils/ApiClient';
import toast from 'react-hot-toast';
import FormItem from 'antd/es/form/FormItem';

const Members = () => {
    const [isloading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openNewMemberModal, setOpenNewMemberModal] = useState(false);
    const [record, setRecord] = useState(null);
    const [form] = Form.useForm();
    const [newMemberForm] = Form.useForm();
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        filterSearch: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        onFilter: (value, record) =>
          record.name.toLowerCase().includes(value.toLowerCase()),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search Name`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={confirm}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={confirm}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
      },
      {
        title: "E mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone no.",
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => {
          return record.phone ? (
            <p>{record.phone}</p>
          ) : (
            <p className="text-black-50">N/A</p>
          );
        },
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        render: (text, record) => {
          return record.address ? (
            <p>{record.address}</p>
          ) : (
            <p className="text-black-50">N/A</p>
          );
        },
      },
      {
        title: "Membership Number",
        dataIndex: "membershipNumber",
        key: "membershipNumber",
        filterSearch: true,
        sorter: (a, b) => Number(a.membershipNumber ? a.membershipNumber : 0) - Number(b.membershipNumber ? b.membershipNumber : 0),
        defaultSortOrder: "ascend",
        onFilter: (value, record) =>
          record.membershipNumber?.toString().includes(value),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search Membership Number`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={confirm}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={confirm}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        render: (text, record) => {
          return <Tag color="green">{record.membershipNumber}</Tag>;
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                handleEditOpen(record);
              }}
            >
              Edit
            </Button>
          </Space>
        ),
      },
    ];
  
    const fetchData = async () => {
      setIsLoading(true);
      await ApiClient.get("/user/getUsersDetails")
        .then((response) => {
          setIsLoading(false);
          setResponseData(response.data);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Failed to fetch data");
        });
    };
  
    const handleEditSubmit = async () => {
      console.log(form.getFieldsValue());
      setIsLoading(true);
      await ApiClient.put("/user/updateMemberDetails", {
        ...form.getFieldsValue(),
        id: record._id,
      })
        .then((response) => {
          setIsLoading(false);
          toast.success("User details updated successfully");
          fetchData();
          setOpen(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Failed to update user details");
        });
    };
  
    const handleEditOpen = (record) => {
      console.log(record);
      setRecord(record);
      setOpen(true);
      form.setFieldsValue({
        name: record.name,
        membershipNumber: record.membershipNumber,
        phone: record.phone,
        address: record.address,
        email: record.email,
      });
    };
  
    const handleEditCancel = () => {
      setOpen(false);
    };
  
    const handleNewMemberOpen = () => {
      setOpenNewMemberModal(true);
    };
  
    const handleNewMemberCancel = () => {
      setOpenNewMemberModal(false);
      form.resetFields();
    };
  
    const handleNewMemberSubmit = () => {
      console.log(newMemberForm.getFieldsValue());
      ApiClient.post("/user/registerNewMember", newMemberForm.getFieldsValue())
        .then((res) => {
          toast.success("New member added successfully");
          setOpenNewMemberModal(false);
          fetchData();
        })
        .catch((err) => {
          toast.error("Failed to add new member");
        });
    };
  
    return (
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="m-2">
            <Button type="primary" onClick={() => fetchData()}>
              <TfiReload size={20} />
            </Button>
          </div>
          <div className="m-2">
            <Button type="primary" onClick={handleNewMemberOpen}>
              <MdPersonAdd size={20} />
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={responseData}
          loading={isloading}
          scroll={{ x: 1300 }}
        />
        <Modal
          title="Edit Membership Details"
          open={open}
          onCancel={handleEditCancel}
          onOk={handleEditSubmit}
        >
          <div>
            <Form form={form}>
              <Form.Item label="Name" name="name">
                <Input name="name" />
              </Form.Item>
  
              <Form.Item label="Membership Number" name="membershipNumber">
                <Input name="membershipNumber" />
              </Form.Item>
              <Form.Item label="Phone" name="phone">
                <Input name="phone" />
              </Form.Item>
              <Form.Item label="Address" name="address">
                <Input name="address" />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input name="email" disabled />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Register New Member"
          open={openNewMemberModal}
          onOk={handleNewMemberSubmit}
          onCancel={handleNewMemberCancel}
        >
          <Form form={newMemberForm}>
            <FormItem name="name" label="Name">
              <Input />
            </FormItem>
            <FormItem name="phone" label="Phone">
              <Input />
            </FormItem>
            <FormItem name="address" label="Address">
              <Input />
            </FormItem>
            <FormItem name="membershipNumber" label="Membership Number">
              <Input />
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
}

export default Members