import React, { useEffect, useState } from "react";
import { ApiClient } from "../../utils/ApiClient";
import { Bill } from "../../utils/Bill";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import { render } from "@testing-library/react";
import toast from "react-hot-toast";
import { Table, Modal, Button, Form, Input, Select } from "antd";
import { Option } from "antd/es/mentions";
import generatePDF from "react-to-pdf";
import ReactToPrint from "react-to-print";
import StatusTag from "./StatusTag";
import { TfiReload } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { getUser } from "../../store/slices/userSlice";

export const PoolSubscriptions = () => {
  const targetRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [barcode, setBarcode] = useState();

  const [newSubscriptionModalOpen, setNewSubscriptionModalOpen] =
    useState(false);
  const [packages, setPackages] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [discountValue, setDiscountValue] = React.useState(0);
  const handlePrint = async () => {
    
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  const user =useSelector(getUser);

  const columns = [
    // {
    //   title: "Subscription ID",
    //   dataIndex: "_id",
    //   key: "_id",
    // },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return <span>Rs.{text}/=</span>;
      }
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record, index) => {
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record, index) => {
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: "Payment",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text)=><StatusTag status={text}/>
    },
    {
      title: "Status",
      dataIndex: "subscriptionStatus",
      key: "subscriptionStatus",
      render: (text)=><StatusTag status={text}/>
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => {
        return (
          <div>
            {record.paymentStatus == "Pending" &&
            record.subscriptionStatus == "Pending" ? (
              <Button onClick={() => handleActivate(record)}>Activate</Button>
            ) : record.subscriptionStatus != "Closed" &&
              record.paymentStatus == "Pending" ? (
              <Button onClick={() => handlePayment(record)}>Payment</Button>
            ) : null}
          </div>
        );
      },
    },
  ];

  
  const [isloading, setIsLoading] = useState(false);
  const fetchPoolSubscriptions = async () => {
setIsLoading(true);
    try {
      const response = await ApiClient.get("/poolSubscription");
      //console.log(response.data);
      setIsLoading(false);
      setData(
        response.data?.map((item) => ({
          ...item,
          userName: item.user?.name,
          packageName: item.poolPackage?.displayName,
        }))
      );
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      toast.error("Error fetching pool subscriptions");
    }
  };

  useEffect(() => {
    fetchPoolSubscriptions();
    fetchPackages();
    getUsers();
  }, []);

  const handleActivate = (record) => {
    //console.log("Activate");
    //console.log(record);
    setSelectedSubscription(record);
    setOpenActivationModal(true);
  };

  const handleActivateClose = () => {
    //console.log("Activate Close");
    setSelectedSubscription(null);
    setOpenActivationModal(false);
  };

  const handleActivateSubmit = () => {
    //console.log("Activate Submit");
    activateSubscription();
    setOpenActivationModal(false);
  };

  const [recordData, setRecordData] = useState([]);

  const handlePayment = (record) => {
    //console.log(record);
    setRecordData(record);
    setBarcode(BarcodeIdGenerate());
    setSelectedSubscription(record);
    setOpenPaymentModal(true);
  };

  const handlePaymentClose = () => {
    //console.log("Payment Close");
    setSelectedSubscription(null);
    setOpenPaymentModal(false);
  };

  const handlePaymentSubmit = () => {
    //console.log("Payment Submit");
    paymentSubscription();
    setOpenPaymentModal(false);
    handlePrint();
  };

  const activateSubscription = async () => {
    try {
      const response = await ApiClient.put(
        `/poolSubscription/activate/${selectedSubscription._id}`
      );
      fetchPoolSubscriptions();
      //console.log(response.data);
      toast.success("Subscription Activated Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Error activating subscription");
    }
  };

  const paymentSubscription = async () => {
    try {
      // console.log(selectedSubscription);
      const response = await ApiClient.put(
        `/poolSubscription/payment/${selectedSubscription._id}`,        {
          cashierId: user._id,
          userId: selectedSubscription.user._id,
          amount: (selectedSubscription.poolPackage.rate- discountValue),
          paymentCategory: "PoolSubscription",
          barcode: barcode,
          discount: discountValue,
        }
      );
      fetchPoolSubscriptions();
      toast.success("Payment Successful");
      //console.log(response.data);
    } catch (error) {
      // console.error(error);
      toast.error("Error in Payment");
    }
  };

  const createSubscription = async () => {
    setNewSubscriptionModalOpen(true); 
    setSelectedPackage("");
    setSelectedUser("");
  };

  const createSubscriptionClose = () => {
    setNewSubscriptionModalOpen(false);
    setSelectedPackage("");
    setSelectedUser("");
  };

  const createSubscriptionSubmit = async () => {
    try {
      const body = {
        userId: selectedUser,
        packageId: selectedPackage,
      };
      const response = await ApiClient.post(`/poolSubscription/`, body);
      fetchPoolSubscriptions();
      createSubscriptionClose();
      toast.success("Subscription Created Successfully");
      //console.log(response.data);
    } catch (error) {
      console.error(error);
      createSubscriptionClose();
      toast.error("Error creating subscription");
    }
  };

  const fetchPackages = async () => {
    
    try {
      const response = await ApiClient.get("/pool-packages");
      setPackages(response.data.filter((item) => item.duration != 0.2));
      //console.log(response.data);
    } catch (error) {
      // console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await ApiClient.get("/user/getUsers");
      setUsers(response.data);
      //console.log(response.data);
    } catch (error) {
      // console.error(error);
    }
  };
  
  const prepareUserOptions = () => {
    const userOptions = users
      ?.filter(
        (item) =>
          item.poolSubscription == null || item.poolSubscription == undefined
      )
      .map((item) => (
        <Select.Option key={item._id} value={item._id} label={item.email}>
          {item.email}
        </Select.Option>
      ));
  
    return [
      <Select.Option key="default" value="">
        Select User
      </Select.Option>,
      ...userOptions,
    ];
  };
  

  return (
    <div>
      <div className=" d-flex justify-content-center gap-2">
        <Button type="primary" onClick={createSubscription}>
          + New Subscription
        </Button>
        <div>
          <Button type="primary" onClick={()=>fetchPoolSubscriptions()}>
            <TfiReload />
          </Button>
        </div>
      </div>
      <Table columns={columns} dataSource={data} loading={isloading}  scroll={{x:1300}}/>
      <Modal
        title="Activate Subscription"
        open={openActivationModal}
        onCancel={handleActivateClose}
        onOk={handleActivateSubmit}
      />
      <Modal
        title="Payment Subscription"
        open={openPaymentModal}
        onCancel={handlePaymentClose}
        footer={[
          <Button key="back" onClick={handlePaymentClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <Bill
          targetRef={targetRef}
          tempItem={[
            {
              name: recordData?.packageName,
              count: 1,
              price: recordData?.poolPackage?.rate,
            },
          ]}
          total={recordData?.poolPackage?.rate * 1}
          subTotal={recordData?.poolPackage?.rate * 1}
          balance={recordData?.poolPackage?.rate * 1}
          barcode={barcode}
          customer={recordData?.userName}
          discount={0}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
        />
      </Modal>
      <Modal
        title="Create New Subscription"
        open={newSubscriptionModalOpen}
        onCancel={createSubscriptionClose}
        onOk={createSubscriptionSubmit}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          layout="horizontal"
          labelAlign="left"
        >
          <Form.Item label="Package Name" name="packageName">
            <Select
              value={selectedPackage}
              onChange={(value) => {
                setSelectedPackage(value);
              }}
            >
              <Select.Option  value="">
                  Select Package
                </Select.Option>
              {packages.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.displayName}
                </Select.Option>
              ))}
              
            </Select>
          </Form.Item>
          <Form.Item label="User Name" name="userName">
            <Select
              value={selectedUser}
              onChange={(value) => {
                setSelectedUser(value);
              }}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {/* {users.map((item) => (
                <Select.Option value={item._id} key={item._id}>{`${item.name} (${item.email})`}</Select.Option>
              ))} */}
              {prepareUserOptions()}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
