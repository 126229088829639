import { Table } from "antd";
import React from "react";

export const AnalyticsPrint = ({
  dataSource,
  columns,
  selectedDate,
  total,
}) => {
  return (
    <div className=" d-flex justify-content-center" id="printableComponent">
      <div style={{ width: "90%" }}>
        <div style={{ marginBottom: 20, textAlign: "center", marginTop: 20 }}>
          <h1 style={{ margin: 0 }}>Daily Report</h1>
          <p style={{ margin: 0 }}>{selectedDate}</p>
        </div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        <div className="d-flex justify-content-end my-4">
          <h2>Total: {total}</h2>
        </div>
      </div>
    </div>
  );
};
