import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Table } from "antd";
import { ApiClient } from "../../utils/ApiClient";
import { DatePicker } from "antd";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import moment from "moment";
import { render } from "@testing-library/react";
import { TfiReload } from "react-icons/tfi";
import { Modal, Form, Row, Col } from "antd";
import { LuPrinter } from "react-icons/lu";
import { AnalyticsPrint } from "./AnalyticsPrint";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const Analytics = () => {
  const [reason, setReason] = useState("");
  const [recordID, setRecordID] = useState("");
  const [open, setOpen] = useState(false);
  const [componentSize, setComponentSize] = useState("large");

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };
  const printRef = useRef();
  const printComponent = () => {
    const input = printRef.current;
    if (input) {
      // Temporarily show the element
      input.style.display = "block";

      setTimeout(() => {
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210; // Width of A4 page in mm
            const pageHeight = 295; // Height of A4 page in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
              position = heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }

            pdf.save(`Daily_Report_${selectedDate}.pdf`);
          })
          .catch((error) => {
            console.error("Error generating PDF:", error);
          })
          .finally(() => {
            // Hide the element again after printing
            input.style.display = "none";
          });
      }, 500); // Adjust the delay as needed
    }
  };

  const handleDelete = async () => {
    try {
      const response = await ApiClient.delete(`/payment/${recordID}`, {
        reason: reason,
      });
      toast.success("Admin will delete the payment soon!");
      fetchPayments(selectedDate);
    } catch (error) {
      toast.error("Failed to delete payment");
    }
  };

  const handleSubmit = () => {
    handleDelete();
    setOpen(false);
    setReason("");
  };

  const [payments, setPayments] = useState([]);
  const [income, setIncome] = useState(0);

  const [isloading, setIsLoading] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    fetchPayments(today);
  }, []);

  const [selectedDate, setSelectedDate] = useState(today);

  const onChange = (date, dateString) => {
    setSelectedDate(dateString);

    fetchPayments(dateString);
  };

  const fetchPayments = async (date) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/payment/date/${date}`);
      setPayments(response.data);
      console.log(response.data);
      setIsLoading(false);
      setIncome(response.data.reduce((acc, curr) => acc + curr.balance, 0));
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      toast.error("Failed to fetch payments");
    }
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: ["userId", "name"],
      key: "name",
      render: (text, record) => {
        return (
          record.userName !== undefined
            ? record.userName
            : record.customerName !== undefined
            ? record.customerName.split(" ")[0]
            : record.cashierId?.name
        )?.split(" ")[0];
      },
    },    
    {
      title: "Invoice No",
      dataIndex: "barcode",
      key: "barcode",
    },
    {
      title: "Payment Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleTimeString(),
    },
    {
      title: "Cashier",
      dataIndex: ["cashierId", "name"],
      key: "name",
      render: (text, record) => {
        return record.cashierId.name?.split(" ")[0];
      },
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>{`Rs.${record.amount}/=`}</span>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => (
        <span>{record.discount === 0 ? "0" : `Rs. ${record.discount}/=`}</span>
      ),
    },
    {
      title: "Fine",
      dataIndex: "fine",
      key: "fine",
      render: (text, record) => (
        <span>{record.fine === 0 ? "0" : `Rs. ${record.fine}/=`}</span>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => <span>{`Rs.${record.balance}/=`}</span>,
    },
    {
      title: "Status",
      dataIndex: "paymentCategory",
      key: "paymentCategory",
      render: (text, record) => (text === "Other" ? record.reason : text),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <div>
    //       <MdDelete
    //       onClick={() => {setRecordID(record._id);
    //         setOpen(true);
    //       }}
    //       style={{
    //         scale: "1.5",
    //       }} />
    //     </div>
    //   ),
    // }
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ fontWeight: "bold", marginRight: "10px" }}>
            Date:
          </label>
          <DatePicker
            style={{ width: "200px" }} // Adjust the width as needed
            onChange={onChange}
            disabledDate={(current) => current && current > moment()}
          />
        </div>
        <div className=" d-flex gap-2">
          <Button type="primary" onClick={() => fetchPayments(today)}>
            <TfiReload />
          </Button>
          <Button type="primary" onClick={printComponent}>
            <LuPrinter />
          </Button>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ fontWeight: "bold", marginRight: "10px" }}>
            Total:
          </label>
          <input
            style={{
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
            }}
            type="text"
            value={`Rs.${income}/=`}
            readOnly
          />
        </div>
      </div>
      <Table
        dataSource={payments}
        columns={columns}
        loading={isloading}
        scroll={{ x: 1300 }}
      />
      <div ref={printRef} style={{ display: "none" }}>
        <AnalyticsPrint
          dataSource={payments}
          columns={columns}
          selectedDate={selectedDate}
          total={income}
        />
      </div>
      <Modal
        title={`Delete Payment under Admin Approval`}
        centered
        open={open}
        onOk={() => handleSubmit()}
        okButtonProps={{
          disabled: !reason,
        }}
        onCancel={() => {
          setOpen(false);
          setReason("");
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="Reason">
                  <Input
                    style={{ width: "100%" }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
