import React, { useEffect } from "react";
import { Button, Space, Table, Tag } from "antd";
import ModalEdit from "./ModalEdit";
import { useState } from "react";
import { Modal, Input } from "antd";
import { Form, InputNumber } from "antd";
import { Row, Col,Popconfirm } from "antd";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";
import { DeleteOutlined } from "@ant-design/icons"; 

const PoolBooking = () => {
  const [packageData, setPackageData] = useState();
  const [datas, setDatas] = useState([]);
  const [attitude, setAttitude] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [componentSize, setComponentSize] = useState("large");
  const [form, setForm] = useState([]);
  const [newValue, setNewValue] = useState();
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    // {
    //   title: "Package Id",
    //   dataIndex: "_id",
    //   key: "_id"
    // },
    {
      title: "Package Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, key) => (
        <a id="displayName" onClick={(e) => handleClick(key, e.target.id)}>
          {text}
        </a>
      ),
    },
    {
      title: "Price",
      dataIndex: "rate",
      key: "rate",
      // render: (text)=>{return <span>Rs.{text}/=</span>}
      render: (text, key) => (
        <a id="rate" onClick={(e) => handleClick(key, e.target.id)}>
          Rs.{text}/=
        </a>
      ),
    },
    {
      title: "Pax",
      dataIndex: "pax",
      key: "pax",
      // render: (text, key) => (
      //   <a
      //     id="minimumNumberOfParticipants"
      //     onClick={(e) => handleClick(key, e.target.id)}
      //   >
      //     {text}
      //   </a>
      // ),
      render: (text,key)=>(
        <span>{`${key.pax > 1 ? key.pax + " Persons" : key.pax + " Person"}`}</span>
      )
    },
    {
      title: "Duration (Months)",
      dataIndex: "duration",
      key: "duration",
      render: (text, key) => {
        const duration = key.duration;
        return (
          <span>
            {duration <= 0.2 ? "1 Day" : `${duration} Months`}
          </span>
        );
      },
    },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   render: (text, key) => (
    //     <Popconfirm
    //       title="Are you sure to delete this package?"
    //       onConfirm={() => handleDelete(key._id)}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Button
    //         icon={<DeleteOutlined />} // Use the Delete icon
    //         type="danger"
    //       />
    //     </Popconfirm>
    //   ),
    // },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    await ApiClient.get("/pool-packages/")
      .then((response) => {
        setPackageData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setIsLoading(false);
        toast.error("Failed to fetch packages");
      });
  };

  const handleDelete = async (id) => {
    //console.log(id);
    await ApiClient.delete(`/pool-packages/${id}`)
      .then((response) => {
        //console.log(response);
        fetchData();
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleOk = async () => {
    
    setIsModalOpen(false);
    await ApiClient.put(`/pool-packages/${datas._id}`, {
      value: newValue,
      attitude: attitude,
    })
      .then((response) => {
        //console.log(response);
        fetchData();
        toast.success("Updated Successfully");
      })
      .catch((error) => {
        // console.error(error);
        toast.error("Failed to update");
      });
  };

  const handleSubmitAdd = async () => {
    isloading(true);
    setOpenAddModal(false);
    await ApiClient.post(`/pool-packages/`, {
      name: form.name,
      rate: form.rate,
      minimumNumberOfParticipants: form.minimumNumberOfParticipants,
    })
      .then((response) => {
        //console.log(response);
        fetchData();
        setIsLoading(false);
        toast.success("Added Successfully");
      })
      .catch((error) => {
        setIsLoading(false);
        // console.error(error);
        toast.error("Failed to add");
      });
  };

  const checkQuantity = (rule, value, callback) => {
    if (value <= 0) {
      callback("Quantity must be greater than 0");
    } else {
      callback();
    }
  };

  const handleClick = (key, data) => {
    setDatas(key);
    setAttitude(data);
    setIsModalOpen(true);
    setDatas(key);
    setNewValue(key[data]);
  };
  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handleAdd = async () => {
    setOpenAddModal(true);
  };

  return (
    <div className="container">
      <ModalEdit
        datas={datas}
        attitude={attitude}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        newValue={newValue}
        setNewValue={setNewValue}
      />
      <Table columns={columns} dataSource={packageData} loading={isloading}  scroll={{x:1300}}/>
      {/* <Button onClick={() => handleAdd()}>Add New Package</Button> */}
      <Modal
        title="Modal 1000px width"
        centered
        open={openAddModal}
        onOk={() => handleSubmitAdd()}
        onCancel={() => setOpenAddModal(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={20} offset={0}>
              <Form
                labelCol={{
                  span: 15,
                }}
                wrapperCol={{
                  span: 40,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                style={{
                  maxWidth: 600,
                }}
              >
                <Form.Item label="Package Name">
                  <Input
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Quantity"
                  rules={[{ validator: checkQuantity }]}
                >
                  <InputNumber
                    min={1}
                    value={form.minimumNumberOfParticipants}
                    onChange={(value) =>
                      setForm({ ...form, minimumNumberOfParticipants: value })
                    }
                  />
                </Form.Item>

                <Form.Item label="Rate" rules={[{ validator: checkQuantity }]}>
                  <InputNumber
                    min={1}
                    value={form.rate}
                    onChange={(value) => setForm({ ...form, rate: value })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default PoolBooking;
