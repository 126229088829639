import React from "react";
import { Button } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import { Bill } from "../../utils/Bill";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import generatePDF from "react-to-pdf";
import ReactToPrint from "react-to-print";
import { PRICES } from "../../utils/Prices";

export const GymSchool = () => {
  const targetRef = React.useRef(null);
  const [qty, setQty] = useState(null);
  const [componentSize, setComponentSize] = useState("large");
  const [discountValue, setDiscountValue] = React.useState(0);
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const customerName = "VCC_Balangoda";
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const user = useSelector((state) => state.user.user);

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  const handlePaymentClose = () => {
    setOpenPaymentModal(false);
    setAmount(null);
    setReason(null);
    setQty(null);
  };

  const handlePaymentSubmit = async () => {
    setOpenPaymentModal(false);
    handlePrint();
    try {
      await ApiClient.post("/payment", {
        customerName: customerName,
        amount:  PRICES.SCHOOL_MEMBERSHIP_PRICE * qty,
        reason: reason,
        barcode: barcode,
        cashierId: user._id,
        paymentCategory: "Other",
        balance: ((PRICES.SCHOOL_MEMBERSHIP_PRICE * qty)- discountValue),
        discount: discountValue,
      });
      
      setAmount(null);
      setReason(null);
      setQty(null);
      toast.success("Bill Payment Successfully");
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = () => {
    setOpen(false);
    setOpenPaymentModal(true);
    setBarcode(BarcodeIdGenerate());
  };

  useEffect(() => {
    setOpen(true);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div className="d-flex justify-content-center my-2 gap-3">
      <Button
        type="primary"
        className=""
        onClick={() => {
          setOpen(true);
        }}
      >
        Create New Bill
      </Button>

      <Modal
        title="Make a Custom Bill"
        centered
        open={open}
        onOk={() => handleSubmit()}
        onCancel={() => {
          setOpen(false);
          setQty(null);
          setReason(null);
          setQty(null);
        }}
        okButtonProps={{
          disabled: !reason || !qty || !customerName,
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="Customer Name">
                  <Input
                    style={{ width: "100%" }}
                    value={customerName}
                    readOnly
                  />
                </Form.Item>

                <Form.Item label="Quantity">
                  <Input
                    style={{ width: "100%" }}
                    type="number"
                    value={qty}
                    onChange={(e) => {
                      setQty(e.target.value);
                      setReason(
                        `Gym Subscription for ${e.target.value} Students`
                      );
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Payment Subscription"
        open={openPaymentModal}
        onCancel={handlePaymentClose}
        footer={[
          <Button key="back" onClick={handlePaymentClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <div>
          <Bill
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
            targetRef={targetRef}
            tempItem={[
              {
                name: reason,
                count: qty,
                price: PRICES.SCHOOL_MEMBERSHIP_PRICE,
              },
            ]}
            total={PRICES.SCHOOL_MEMBERSHIP_PRICE * qty}
            subTotal={PRICES.SCHOOL_MEMBERSHIP_PRICE * qty}
            balance={PRICES.SCHOOL_MEMBERSHIP_PRICE * qty}
            barcode={barcode}
            customer={customerName}
            discount={0}
          />
        </div>
      </Modal>
    </div>
  );
};
