import React from "react";
import { Button } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import { Bill } from "../../utils/Bill";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import generatePDF from "react-to-pdf";
import ReactToPrint from "react-to-print";


export const CustomBill = () => {
  const targetRef = React.useRef(null);
  const [componentSize, setComponentSize] = useState("large");
  const [reason, setReason] = useState(null);
  const [amount, setAmount] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [discountValue, setDiscountValue] = React.useState(0);
  const [customerName, setCustomerName] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const user = useSelector((state) => state.user.user);

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  const handlePaymentClose = () => {
    setOpenPaymentModal(false);
    setAmount(null)
    setReason(null)
    setCustomerName(null)
  };

    const handlePaymentSubmit = async() => {
      handlePrint();
        setOpenPaymentModal(false);
        try{
        await ApiClient.post("/payment", {
            customerName: customerName,
            amount: (amount- discountValue),
            reason: reason,
            barcode: barcode,
            cashierId: user._id,
            paymentCategory:"Other",
            balance: amount,
            discount: discountValue
        });
        setAmount(null)
        setReason(null)
        setCustomerName(null)
        toast.success("Bill Payment Successfully")
    }catch(e){
        // console.log(e)
        toast.error("Something went wrong")
    }

    };

  const handleSubmit = () => {
    setOpen(false);
    setOpenPaymentModal(true);
    setBarcode(BarcodeIdGenerate());
  };

  useEffect(() => {
    setOpen(true);
  }, []);
  const [open, setOpen] = useState(false);
  return (
    <div className="d-flex justify-content-center my-2 gap-3">
      <Button
        type="primary"
        className=""
        onClick={() => {
          setOpen(true);
        }}
      >
        Create New Bill
      </Button>
      
      <Modal
        title="Make a Custom Bill"
        centered
        open={open}
        onOk={() => handleSubmit()}
        onCancel={() => {
            setOpen(false)
            setAmount(null)
            setReason(null)
            setCustomerName(null)
        }}
        okButtonProps={
            {
                disabled: !reason || !amount || !customerName
        }
        }
      >
        <div>
        <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="Customer Name">
                  <Input
                    style={{ width: "100%" }}
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Reason">
                  <Input
                    style={{ width: "100%" }}
                    value={reason}
                    maxLength={25}
                    onChange={(e) => {
                      if (e.target.value.length <= 25) {
                        setReason(e.target.value);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item label="Amount">
                  <Input
                    style={{ width: "100%" }}
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Payment Subscription"
        open={openPaymentModal}
        onCancel={handlePaymentClose}
        footer={[
          <Button key="back" onClick={handlePaymentClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <Bill
          targetRef={targetRef}
          tempItem={
            [{
              name: reason,
              count: 1,
              price: amount,
            
            }]
          }
          total={amount }
          
          subTotal={amount }
          balance={amount }
          barcode={barcode}
          customer={customerName}
          discount={0}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
        />
      </Modal>
    </div>
  );
};
